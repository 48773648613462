<template>
  <div class="flex flex-col lg:flex-row lg:max-w-screen-lg lg:mx-auto xl:max-w-screen-xl">
    <!-- Navigation -->
    <header class="lg:w-1/5" @click="scrollTop">
      <div class="flex justify-between pt-2 sm:pb-2 px-4 lg:flex-col lg:gap-4 lg:sticky lg:top-0" @click.stop>
        <NuxtLink href="/"><img src="public/logo.svg" alt="Western Montana News" class="w-28 lg:w-full" /></NuxtLink>
        <DesktopNavigation />
      </div>
    </header>

    <!-- Main Content -->
    <main class="pb-20 lg:w-5/6 lg:ring-1 lg:ring-gray-200 dark:lg:ring-gray-800">
      <slot />
  
      <SiteFooter />
    </main>

    <!-- Mobile Navigation -->
    <div class="fixed bottom-0 left-0 right-0 ring-1 ring-gray-200 dark:ring-gray-800 backdrop-blur-lg bg-white/90 dark:bg-gray-800/90 lg:hidden">
      <MobileNavigation class="lg:hidden" />
    </div>

  </div>
</template>

<script setup lang="ts">

const scrollTop = () => {
  useScroll(window, { behavior: 'smooth' }).y.value = 0;
}
</script>